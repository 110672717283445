
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align:left;"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'120px'">
        <el-form-item label="学校名称" prop="companyId">
          <el-select-tree
            :props="props"
            :options="companyResult"
            v-model="formModel.companyId"
            height="200"
          ></el-select-tree>
        </el-form-item>
        <el-form-item label="使用场景" prop="scenes">
          <el-select v-model="formModel.scenes" filterable placeholder="请选择" style="width:50%">
            <el-option
              v-for="result in pointTypeData"
              :key="result.id"
              :label="result.name"
              :value="result.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="老师积分类型" prop="type">
          <el-select v-model="formModel.type" filterable placeholder="请选择" style="width:50%">
            <el-option label="积分" value="1"></el-option>
            <el-option label="百分比" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="老师积分" prop="point">
          <el-input v-model="formModel.point" placeholder="请输入老师获取积分" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="学生积分类型" prop="studentType">
          <el-select v-model="formModel.studentType" filterable placeholder="请选择" style="width:50%">
            <el-option label="积分" value="1"></el-option>
            <el-option label="百分比" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学生积分" prop="studentPoint">
          <el-input v-model="formModel.studentPoint" placeholder="请输入学生获取积分" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="是否启用" prop="isEnable">
          <el-radio-group v-model="formModel.isEnable">
            <el-radio :label="true">启用</el-radio>
            <el-radio :label="false">未启用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import pointsInfoApi from "@/api/base/pointsInfo";
import dataDictionaryApi from "@/api/sys/dataDictionary";
import SelectTree from "@/components/SelectTree";

export default {
  props: ["businessKey", "title", "companyResult"],
  components: {
    "el-select-tree": SelectTree,
  },
  data() {
    return {
      formModel: {},
      ruleValidate: {
        companyId: [
          { required: true, message: "请选择学校", trigger: "blur" },
        ],
        scenes: [
          { required: true, message: "请选择使用场景", trigger: "blur" },
        ],
        type: [
          { required: true, message: "请选择类型", trigger: "blur" },
        ],
        point: [
          { required: true, message: "分值不能为空", trigger: "blur" },
        ],
        isEnable: [
          { required: true, message: "请选择是否启用", trigger: "blur" },
        ],
      },
      showDialog: true,
      loading: false,
      submitting: false,
      pointTypeData: [],
      treeData: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children",
      },
    };
  },
  created() {
    var formData = new FormData();
    formData.append("catalogName", "积分使用场景");
    dataDictionaryApi.findByCatalogName(formData).then((response) => {
      var jsonData = response.data;
      this.pointTypeData = jsonData.data;
    });
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            if (id == null || id.length == 0) {
              return pointsInfoApi.add(self.formModel);
            } else {
              return pointsInfoApi.update(self.formModel);
            }
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
  },
  mounted: function () {
    var self = this;

    (function () {
      if (self.businessKey.length == 0) {
        return pointsInfoApi.create();
      } else {
        return pointsInfoApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
};
</script>